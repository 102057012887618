export const appHead = {"meta":[{"charset":"utf-8"},{"name":"author","content":"Компания ООО 'Мой Автопрокат'"},{"property":"og:image","content":"https://mycarrental.ru/mainpagee-image.jpg"},{"name":"viewport","content":"width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0, user-scalable=no"},{"name":"yandex-verification","content":"0144e01cd93937da"},{"name":"google-site-verification","content":"LOPzIy1GYK-sP03cd5FZ6ypNvbyE8ZWboy6byz5MiUE"}],"link":[],"style":[],"script":[{"id":"metrika","innerHTML":"(function(m,e,t,r,i,k,a){\n    m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n    m[i].l=1*new Date();\n    k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n    (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");"}],"noscript":[],"title":"Аренда авто посуточно без водителя – прокат легковых автомобилей недорого, доступные цены | Мой Автопрокат"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false