export default defineNuxtPlugin(() => {
  if (process.client) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = "//c.retailcrm.tech/widget/loader.js";
    
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);

    window._rcct = "4dfd5b7d6bc9f10cebcf3cdeb03183408b5ce538be90f54c2251ec5753e30c39";
  }
});