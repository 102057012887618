import { default as _slugpp6LLbiO55Meta } from "/home/nikolay/projects/mcr-nuxt/pages/_slug.vue?macro=true";
import { default as _404nJwy6vPYQOMeta } from "/home/nikolay/projects/mcr-nuxt/pages/404.vue?macro=true";
import { default as _91id_93wQdfGpsbp1Meta } from "/home/nikolay/projects/mcr-nuxt/pages/blog/[id].vue?macro=true";
import { default as indexEseBahDInDMeta } from "/home/nikolay/projects/mcr-nuxt/pages/blog/index.vue?macro=true";
import { default as BookingpReVgq0YykMeta } from "/home/nikolay/projects/mcr-nuxt/pages/Booking.vue?macro=true";
import { default as contactsbZjz94hjygMeta } from "/home/nikolay/projects/mcr-nuxt/pages/contacts.vue?macro=true";
import { default as corporated7Cb4ufQJOMeta } from "/home/nikolay/projects/mcr-nuxt/pages/corporate.vue?macro=true";
import { default as franchisesbHA8tzjSlMeta } from "/home/nikolay/projects/mcr-nuxt/pages/franchise.vue?macro=true";
import { default as indexNvwYhsz1qYMeta } from "/home/nikolay/projects/mcr-nuxt/pages/index.vue?macro=true";
import { default as partnerka6SCPiJNOVIMeta } from "/home/nikolay/projects/mcr-nuxt/pages/partnerka.vue?macro=true";
import { default as _91slug_93HR9j4v52WLMeta } from "/home/nikolay/projects/mcr-nuxt/pages/regions__/[slug].vue?macro=true";
import { default as _91id_93vNajbuEaKYMeta } from "/home/nikolay/projects/mcr-nuxt/pages/reservation/[id].vue?macro=true";
import { default as termsF6fHSJ170YMeta } from "/home/nikolay/projects/mcr-nuxt/pages/terms.vue?macro=true";
import { default as user_45agreementBoePjeTMkPMeta } from "/home/nikolay/projects/mcr-nuxt/pages/user-agreement.vue?macro=true";
import { default as _slugwffj03MhPjMeta } from "~/pages/_slug.vue?macro=true";
import { default as _91id_93xlpFJ7eH29Meta } from "~/pages/blog/[id].vue?macro=true";
import { default as terms6ldi9JYIvUMeta } from "~/pages/terms.vue?macro=true";
import { default as user_45agreementC5NiffWMRKMeta } from "~/pages/user-agreement.vue?macro=true";
import { default as indexp9V2p5fxycMeta } from "~/pages/index.vue?macro=true";
export default [
  {
    name: _slugpp6LLbiO55Meta?.name ?? "_slug",
    path: _slugpp6LLbiO55Meta?.path ?? "/_slug",
    meta: _slugpp6LLbiO55Meta || {},
    alias: _slugpp6LLbiO55Meta?.alias || [],
    redirect: _slugpp6LLbiO55Meta?.redirect,
    component: () => import("/home/nikolay/projects/mcr-nuxt/pages/_slug.vue").then(m => m.default || m)
  },
  {
    name: _404nJwy6vPYQOMeta?.name ?? "404",
    path: _404nJwy6vPYQOMeta?.path ?? "/404",
    meta: _404nJwy6vPYQOMeta || {},
    alias: _404nJwy6vPYQOMeta?.alias || [],
    redirect: _404nJwy6vPYQOMeta?.redirect,
    component: () => import("/home/nikolay/projects/mcr-nuxt/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _91id_93wQdfGpsbp1Meta?.name ?? "blog-id",
    path: _91id_93wQdfGpsbp1Meta?.path ?? "/blog/:id()",
    meta: _91id_93wQdfGpsbp1Meta || {},
    alias: _91id_93wQdfGpsbp1Meta?.alias || [],
    redirect: _91id_93wQdfGpsbp1Meta?.redirect,
    component: () => import("/home/nikolay/projects/mcr-nuxt/pages/blog/[id].vue").then(m => m.default || m)
  },
  {
    name: indexEseBahDInDMeta?.name ?? "blog",
    path: indexEseBahDInDMeta?.path ?? "/blog",
    meta: indexEseBahDInDMeta || {},
    alias: indexEseBahDInDMeta?.alias || [],
    redirect: indexEseBahDInDMeta?.redirect,
    component: () => import("/home/nikolay/projects/mcr-nuxt/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: BookingpReVgq0YykMeta?.name ?? "Booking",
    path: BookingpReVgq0YykMeta?.path ?? "/Booking",
    meta: BookingpReVgq0YykMeta || {},
    alias: BookingpReVgq0YykMeta?.alias || [],
    redirect: BookingpReVgq0YykMeta?.redirect,
    component: () => import("/home/nikolay/projects/mcr-nuxt/pages/Booking.vue").then(m => m.default || m)
  },
  {
    name: contactsbZjz94hjygMeta?.name ?? "contacts",
    path: contactsbZjz94hjygMeta?.path ?? "/contacts",
    meta: contactsbZjz94hjygMeta || {},
    alias: contactsbZjz94hjygMeta?.alias || [],
    redirect: contactsbZjz94hjygMeta?.redirect,
    component: () => import("/home/nikolay/projects/mcr-nuxt/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: corporated7Cb4ufQJOMeta?.name ?? "corporate",
    path: corporated7Cb4ufQJOMeta?.path ?? "/corporate",
    meta: corporated7Cb4ufQJOMeta || {},
    alias: corporated7Cb4ufQJOMeta?.alias || [],
    redirect: corporated7Cb4ufQJOMeta?.redirect,
    component: () => import("/home/nikolay/projects/mcr-nuxt/pages/corporate.vue").then(m => m.default || m)
  },
  {
    name: franchisesbHA8tzjSlMeta?.name ?? "franchise",
    path: franchisesbHA8tzjSlMeta?.path ?? "/franchise",
    meta: franchisesbHA8tzjSlMeta || {},
    alias: franchisesbHA8tzjSlMeta?.alias || [],
    redirect: franchisesbHA8tzjSlMeta?.redirect,
    component: () => import("/home/nikolay/projects/mcr-nuxt/pages/franchise.vue").then(m => m.default || m)
  },
  {
    name: indexNvwYhsz1qYMeta?.name ?? "index",
    path: indexNvwYhsz1qYMeta?.path ?? "/",
    meta: indexNvwYhsz1qYMeta || {},
    alias: indexNvwYhsz1qYMeta?.alias || [],
    redirect: indexNvwYhsz1qYMeta?.redirect,
    component: () => import("/home/nikolay/projects/mcr-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: partnerka6SCPiJNOVIMeta?.name ?? "partnerka",
    path: partnerka6SCPiJNOVIMeta?.path ?? "/partnerka",
    meta: partnerka6SCPiJNOVIMeta || {},
    alias: partnerka6SCPiJNOVIMeta?.alias || [],
    redirect: partnerka6SCPiJNOVIMeta?.redirect,
    component: () => import("/home/nikolay/projects/mcr-nuxt/pages/partnerka.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HR9j4v52WLMeta?.name ?? "regions__-slug",
    path: _91slug_93HR9j4v52WLMeta?.path ?? "/regions__/:slug()",
    meta: _91slug_93HR9j4v52WLMeta || {},
    alias: _91slug_93HR9j4v52WLMeta?.alias || [],
    redirect: _91slug_93HR9j4v52WLMeta?.redirect,
    component: () => import("/home/nikolay/projects/mcr-nuxt/pages/regions__/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vNajbuEaKYMeta?.name ?? "reservation-id",
    path: _91id_93vNajbuEaKYMeta?.path ?? "/reservation/:id()",
    meta: _91id_93vNajbuEaKYMeta || {},
    alias: _91id_93vNajbuEaKYMeta?.alias || [],
    redirect: _91id_93vNajbuEaKYMeta?.redirect,
    component: () => import("/home/nikolay/projects/mcr-nuxt/pages/reservation/[id].vue").then(m => m.default || m)
  },
  {
    name: termsF6fHSJ170YMeta?.name ?? "terms",
    path: termsF6fHSJ170YMeta?.path ?? "/terms",
    meta: termsF6fHSJ170YMeta || {},
    alias: termsF6fHSJ170YMeta?.alias || [],
    redirect: termsF6fHSJ170YMeta?.redirect,
    component: () => import("/home/nikolay/projects/mcr-nuxt/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: user_45agreementBoePjeTMkPMeta?.name ?? "user-agreement",
    path: user_45agreementBoePjeTMkPMeta?.path ?? "/user-agreement",
    meta: user_45agreementBoePjeTMkPMeta || {},
    alias: user_45agreementBoePjeTMkPMeta?.alias || [],
    redirect: user_45agreementBoePjeTMkPMeta?.redirect,
    component: () => import("/home/nikolay/projects/mcr-nuxt/pages/user-agreement.vue").then(m => m.default || m)
  },
  {
    name: _slugwffj03MhPjMeta?.name ?? "city",
    path: _slugwffj03MhPjMeta?.path ?? "/:slug",
    meta: _slugwffj03MhPjMeta || {},
    alias: _slugwffj03MhPjMeta?.alias || [],
    redirect: _slugwffj03MhPjMeta?.redirect,
    component: () => import("~/pages/_slug.vue").then(m => m.default || m)
  },
  {
    name: _slugwffj03MhPjMeta?.name ?? "region",
    path: _slugwffj03MhPjMeta?.path ?? "/regions/:slug",
    meta: _slugwffj03MhPjMeta || {},
    alias: _slugwffj03MhPjMeta?.alias || [],
    redirect: _slugwffj03MhPjMeta?.redirect,
    component: () => import("~/pages/_slug.vue").then(m => m.default || m)
  },
  {
    name: _91id_93xlpFJ7eH29Meta?.name ?? "news_page",
    path: _91id_93xlpFJ7eH29Meta?.path ?? "/regions/:city/:slug",
    meta: _91id_93xlpFJ7eH29Meta || {},
    alias: _91id_93xlpFJ7eH29Meta?.alias || [],
    redirect: _91id_93xlpFJ7eH29Meta?.redirect,
    component: () => import("~/pages/blog/[id].vue").then(m => m.default || m)
  },
  {
    name: terms6ldi9JYIvUMeta?.name ?? "terms",
    path: terms6ldi9JYIvUMeta?.path ?? "/terms",
    meta: terms6ldi9JYIvUMeta || {},
    alias: terms6ldi9JYIvUMeta?.alias || [],
    redirect: terms6ldi9JYIvUMeta?.redirect,
    component: () => import("~/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: user_45agreementC5NiffWMRKMeta?.name ?? "user-agreement",
    path: user_45agreementC5NiffWMRKMeta?.path ?? "/user-agreement",
    meta: user_45agreementC5NiffWMRKMeta || {},
    alias: user_45agreementC5NiffWMRKMeta?.alias || [],
    redirect: user_45agreementC5NiffWMRKMeta?.redirect,
    component: () => import("~/pages/user-agreement.vue").then(m => m.default || m)
  },
  {
    name: indexp9V2p5fxycMeta?.name ?? "index",
    path: indexp9V2p5fxycMeta?.path ?? "/",
    meta: indexp9V2p5fxycMeta || {},
    alias: indexp9V2p5fxycMeta?.alias || [],
    redirect: indexp9V2p5fxycMeta?.redirect,
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  }
]