import { ref } from 'vue';

const isVisible = ref(false);

export function showLoader() {
  isVisible.value = true;
}

export function hideLoader() {
  isVisible.value = false;
}

export function useLoader() {
  return { isVisible };
}
