import { defineNuxtPlugin } from '#app';
import { useRouter } from 'vue-router';
import { showLoader, hideLoader } from '@/composables/useLoader';

export default defineNuxtPlugin(() => {
  const router = useRouter();

  router.beforeEach((to, from, next) => {
    showLoader(); 
    next();
  });

  router.afterEach(() => {
    setTimeout(() => {
      hideLoader(); 
    }, 3000); 
  });
});
