import VueTelInput from 'vue-tel-input';
import '@/assets/css/vue-tel-input.css';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueTelInput, {
    inputOptions: {
      placeholder: 'Телефон',
      showDialCode: true
    },
    mode: 'international',
  });
});