import revive_payload_client_4sVQNw7RlN from "/home/nikolay/projects/mcr-nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/nikolay/projects/mcr-nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/nikolay/projects/mcr-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/nikolay/projects/mcr-nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/nikolay/projects/mcr-nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/nikolay/projects/mcr-nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/nikolay/projects/mcr-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/nikolay/projects/mcr-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_9MY4eAfYMX from "/home/nikolay/projects/mcr-nuxt/node_modules/@artmizu/yandex-metrika-nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/nikolay/projects/mcr-nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import map_lIPpjtfXuV from "/home/nikolay/projects/mcr-nuxt/plugins/map.ts";
import awesome_dxYinEZglg from "/home/nikolay/projects/mcr-nuxt/plugins/awesome.ts";
import calendar_7C5YHq9ZuD from "/home/nikolay/projects/mcr-nuxt/plugins/calendar.ts";
import disablePageScroll_5wltiRzxyW from "/home/nikolay/projects/mcr-nuxt/plugins/disablePageScroll.ts";
import enablePageScroll_2tqe7b6B1o from "/home/nikolay/projects/mcr-nuxt/plugins/enablePageScroll.ts";
import getFormattedNumber_1CdyJYu7KI from "/home/nikolay/projects/mcr-nuxt/plugins/getFormattedNumber.ts";
import getFormattedNumberFloat_6WzmZhTBKE from "/home/nikolay/projects/mcr-nuxt/plugins/getFormattedNumberFloat.ts";
import getNumberLabels_JbxxaeRayZ from "/home/nikolay/projects/mcr-nuxt/plugins/getNumberLabels.ts";
import getTransmissionLabel_CrtS31MBUH from "/home/nikolay/projects/mcr-nuxt/plugins/getTransmissionLabel.ts";
import infinite_3Tu7KBt69w from "/home/nikolay/projects/mcr-nuxt/plugins/infinite.ts";
import inputTel_adIZJlOJaA from "/home/nikolay/projects/mcr-nuxt/plugins/inputTel.ts";
import loader_overlay_client_fbB16255Dj from "/home/nikolay/projects/mcr-nuxt/plugins/loader-overlay.client.ts";
import maska_UHaKf2z1iQ from "/home/nikolay/projects/mcr-nuxt/plugins/maska.ts";
import moment_NWWiRYbNqj from "/home/nikolay/projects/mcr-nuxt/plugins/moment.ts";
import pagination_tvK67euIKy from "/home/nikolay/projects/mcr-nuxt/plugins/pagination.ts";
import retailcrm_Kguj4FX3ho from "/home/nikolay/projects/mcr-nuxt/plugins/retailcrm.ts";
import router_WMnTGednOQ from "/home/nikolay/projects/mcr-nuxt/plugins/router.ts";
import toast_ysMjUcU7eJ from "/home/nikolay/projects/mcr-nuxt/plugins/toast.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_9MY4eAfYMX,
  chunk_reload_client_UciE0i6zes,
  map_lIPpjtfXuV,
  awesome_dxYinEZglg,
  calendar_7C5YHq9ZuD,
  disablePageScroll_5wltiRzxyW,
  enablePageScroll_2tqe7b6B1o,
  getFormattedNumber_1CdyJYu7KI,
  getFormattedNumberFloat_6WzmZhTBKE,
  getNumberLabels_JbxxaeRayZ,
  getTransmissionLabel_CrtS31MBUH,
  infinite_3Tu7KBt69w,
  inputTel_adIZJlOJaA,
  loader_overlay_client_fbB16255Dj,
  maska_UHaKf2z1iQ,
  moment_NWWiRYbNqj,
  pagination_tvK67euIKy,
  retailcrm_Kguj4FX3ho,
  router_WMnTGednOQ,
  toast_ysMjUcU7eJ
]