<script setup lang="ts">
import { useLoader } from '@/composables/useLoader';
const { isVisible } = useLoader();
</script>

<template>
  <div v-if="isVisible" class="loader-overlay">
    <div class="loader"></div>
  </div>
</template>
  
<style scoped>
  .loader-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  .loader {
    border: 12px solid #f3f3f3;
    border-radius: 50%;
    border-top: 12px solid #FF6D10;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>
  