import { defineNuxtPlugin } from '#app'
import plugin from 'vue-yandex-maps'


const settings = {
  apiKey: '4d02f3b3-45a4-480b-8c3a-60dfe8e5a48d',
  lang: 'ru_RU',
  coordorder: 'latlong',
  debug: false,
  version: '2.1',
  yandexMapDisablePoiInteractivity: true
}
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(plugin, settings)
});